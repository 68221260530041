.project-center {
  color: rgb(255, 240, 208);
  align-items: center;
}

.project-title {
  display: flex;
  align-items: center;
}

.project-title > h1 {
  margin: 0px;
}

.project-title > h1 > a {
  font-size: 24px;
  color: rgb(80, 176, 255);
}

.project-title > h1 > a:hover {
  color: rgb(62, 141, 206);
}

.project-body {
  width: 80%;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto 1fr;
  column-gap: 20px;
  row-gap: 20px;
  flex-direction: column;
}

.project-desc {
  font-size: 18px;
  background-color: rgba(255, 240, 208, 0.08);
  padding: 20px;
  border-radius: calc(4rem / 16);
  grid-row-start: 1;
  grid-column: span 2;
}

.project-stack {
  width: fit-content;
  background-color: rgba(255, 240, 208, 0.08);
  padding: 20px;
  border-radius: calc(4rem / 16);
  display: flex;
  flex-direction: column;
  grid-row-start: 2;
}

.project-stack > h2 {
  margin: 0px;
  font-size: 18px;
}

.project-features {
  background-color: rgba(255, 240, 208, 0.08);
  padding: 20px;
  border-radius: calc(4rem / 16);
  display: flex;
  flex-direction: column;
  grid-row-start: 2;
}

.project-features > h2 {
  margin: 0px;
  font-size: 18px;
}

.project-features > ul > li > span {
  text-decoration: underline;
  font-weight: 500;
}

.project-gallery {
  width: calc(80% - 24px);
  border-radius: calc(4rem / 16);
  overflow: hidden;
  background-color: rgba(255, 240, 208, 0.08);
  padding: 12px;
}

@media screen and (max-width: calc(1600rem/16)) {
  .project-body {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
  }

  .project-desc {
    grid-row-start: 1;
    grid-column: span 1;
  }
  .project-stack {
    grid-row-start: 2;
    grid-column: span 1;
    width: auto;
  }
  .project-features {
    grid-row-start: 3;
    grid-column: span 1;
  }
}
