#projects-center {
  color: white;
  align-items: center;
}

#projects-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#projects-title > h1 {
  margin: 0px;
}

#projects-gallery {
  display: flex;
  row-gap: calc(20rem / 16);
  column-gap: calc(20rem / 16);
  max-width: calc(1200rem / 16);
  flex-wrap: wrap;
  justify-content: center;
}

.projects-project {
  background-color: rgba(255, 240, 208, 0.08);
  height: calc(420rem / 16);
  width: calc(370rem / 16);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: calc(4rem / 16);
  overflow: hidden;
  text-decoration: none;
  color: white;
  position: relative;
}

.projects-projectmask {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.3;
  transition: all 0.2s ease-in-out;
}

.projects-project:hover .projects-projectmask {
  opacity: 1;
}

.projects-project > h2 {
  font-size: calc(28rem / 16);
  z-index: 2;
}

.projects-reveal {
  margin: calc(20rem / 16);
  text-align: justify;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  transition: all 0.2s ease-in-out;
  z-index: 2;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.projects-project:hover .projects-reveal {
  display: block;
  animation: fadeIn 0.5s;
}

#projects-plastiic {
  background-image: url("../../../public/project-plastiic.png");
  background-size: cover;
}

#projects-trillium {
  background-image: url("../../../public/project-trillium.png");
  background-size: cover;
}

#projects-ipayroll {
  background-image: url("../../../public/project-ipayroll.png");
  background-size: cover;
}

#projects-vault24 {
  background-image: url("../../../public/project-vault24.png");
  background-size: cover;
}

#projects-moviemate {
  background-image: url("../../../public/project-moviemate.png");
  background-size: cover;
}
