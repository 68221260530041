#nav-grid {
  display: grid;
  grid-template-columns: 1fr minmax(calc(500rem / 16), calc(1500rem / 16)) 1fr;
  background-color: rgb(25, 25, 41);
  height: calc(110rem / 16);
}

#nav-center {
  grid-column-start: 2;
  padding: 0px calc(24rem / 16);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(25, 25, 41);
}

#nav-title {
  color: rgb(255, 240, 208);
  font-size: calc(48rem / 16);
  font-weight: 700;
  text-decoration: none;
  margin: 0px;
  transition: all 0.2s ease-in-out;
}

#nav-title:hover {
  /* color: coral; */
  color: rgb(80, 176, 255);
}

#nav-center > div {
  display: flex;
  align-items: center;
  column-gap: calc(36rem / 16);
}

.nav-link {
  margin: 0px;
  color: rgb(255, 240, 208);
  font-size: calc(24rem / 16);
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.nav-link:hover {
  /* color: coral; */
  color: rgb(80, 176, 255);
}

.nav-icon {
  transform: translate(0px, 4px);
  color: rgba(255, 240, 208, 0.65);
}

#nav-divider {
  color: rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: calc(800rem/16)) {
  #nav-title {
    font-size: calc(40rem / 16);
  }

  .nav-link {
    font-size: calc(22rem / 16);
  }

  .nav-icon,
  #nav-divider {
    display: none;
  }
}
