.page-grid {
  display: grid;
  grid-template-columns: 1fr minmax(calc(500rem / 16), calc(1500rem / 16)) 1fr;
  background-color: rgb(43, 43, 71);
}

.page-center {
  grid-column-start: 2;
  padding: calc(30rem / 16);
  row-gap: calc(24rem / 16);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(43, 43, 71);
}
