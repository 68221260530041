#landing-page {
  height: 100vh;
  background: rgb(18, 18, 29);
}

#landing-page > h1 {
  position: absolute;
  color: white;
  text-align: center;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0.3;
}

h1#landing-redirecting {
  bottom: 15%;
}

@media screen and (max-width: calc(800rem/16)) {
  #landing-page > h1 {
    font-size: calc(22rem / 16);
  }
}
