#footer-grid {
  display: grid;
  grid-template-columns: 1fr minmax(calc(500rem / 16), calc(1500rem / 16)) 1fr;
  background-color: rgb(18, 18, 29);
  height: calc(100rem / 16);
  color: rgb(255, 240, 208);
}

#footer-center {
  grid-column-start: 2;
  padding: 0px calc(24rem / 16);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(18, 18, 29);
  column-gap: 20px;
  color: rgba(255, 240, 208, 0.24);
}

.footer-icon {
  margin: 0px;
  color: rgba(255, 240, 208, 0.65);
  font-size: calc(28rem / 16);
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  transform: translate(0px, 4px);
}

.footer-icon:hover {
  color: rgb(80, 176, 255);
}
