#resume-center {
  color: white;
  align-items: center;
}

#resume-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#resume-title > h1 {
  margin: 0px;
}

#resume-title > p > a {
  margin: 0px;
  color: rgb(80, 176, 255);
  text-decoration: none;
}

#resume-title > p > a:hover {
  color: rgb(62, 141, 206);
}

#resume-viewer {
  border-radius: calc(4rem / 16);
  overflow: hidden;
}
